<template>
    <div class="login" style="
    background-image: url('https://imgapi.xl0408.top/index.php'); 
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    ">
        <el-row><el-col :xs="0" :sm="14">/
            </el-col>
            <el-col :xs="24" :sm="10" style="background-color: rgba(255, 255, 255, 0.9); height: 100vh;">
                <div class="center-content">
                    <el-avatar :size="100" src="https://chmlfrp.cn/favicon.ico"></el-avatar>
                    <h1>Register to ChmlFrp</h1>
                    <el-form ref="registerForm" :rules="rules" :model="registerData" class="custom-form">
                        <el-form-item prop="username">
                            <el-input v-model="registerData.username" placeholder="用户名"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input :type="passwordFieldType" placeholder="密码" v-model="registerData.password"
                                clearable>
                                <template v-slot:append>
                                    <el-button :icon="showPassword ? 'el-icon-close' : 'el-icon-view'"
                                        @click="togglePasswordVisibility"></el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="confirmPassword">
                            <el-input :type="passwordFieldTypea" placeholder="重复密码"
                                v-model="registerData.confirmPassword" clearable>
                                <template v-slot:append>
                                    <el-button :icon="showPassworda ? 'el-icon-close' : 'el-icon-view'"
                                        @click="togglePasswordVisibilitya"></el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="qq">
                            <el-input v-model="registerData.qq" placeholder="QQ号"></el-input>
                        </el-form-item>
                        <el-form-item prop="email">
                            <el-input v-model="registerData.email" placeholder="邮箱"></el-input>
                        </el-form-item>
                        <el-row :gutter="20">
                            <el-form-item prop="verificationCode">
                                <el-col :span="14">
                                    <el-input v-model="registerData.verificationCode" placeholder="验证码"></el-input>
                                </el-col>
                                <el-col :span="10">
                                    <div v-if="cooldown === 0">
                                        <el-button slot="reference" style="width: 100%;" v-loading="loadingyx"
                                            :disabled="cooldown > 0 || !registerData.email"
                                            @click="initGeetest">发送验证码</el-button>
                                    </div>
                                    <div v-else>
                                        <el-button style="width: 100%;" disabled>{{ cooldown }}</el-button>
                                    </div>
                                </el-col>
                            </el-form-item>
                        </el-row>
                        <el-form-item>
                            <p> 注册即代表您同意CHMLFRP的
                                <el-link type="primary" href="https://chmlfrp.cn/fwtk.html">服务条款</el-link>/
                                <el-link type="primary" href="https://chmlfrp.cn/yscl.html">隐私策略</el-link>
                            </p>
                            <el-button style="width: 100%;" type="primary" v-loading="loadingzc" @click="register"
                                :disabled="isRegistrationDisabled">注册</el-button>
                        </el-form-item>
                    </el-form>
                    <el-link style="top: 20px;" :underline="false" href="/login">已有帐户？点击登录</el-link>
                    <p style="position: fixed; bottom: 0; width: 100%; text-align: center; font-size: 14px;">
                        Copyright © 2021 - 2025 ChmlFrp All rights reserved.
                    </p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            geetestCaptcha: null,
            geetestConfig: {
                captchaId: "8253677cc86aae19e1b760f01d78ef27",
            },
            loadingyx: false,
            loadingzc: false,
            isRegistrationDisabled: false,
            lp: '',
            encryptedPassword: '',
            registerData: {
                username: '',
                password: '',
                confirmPassword: '',
                qq: '',
                email: '',
                verificationCode: '',
            },
            showPassword: false,
            showPassworda: false,
            cooldown: 0,
            rules: {
                username: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { validator: this.validateUsername, trigger: 'blur' },
                ],
                qq: [
                    { required: true, message: '请输入QQ号', trigger: 'blur' },
                    { validator: this.validateQQ, trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: this.validatePassword, trigger: 'blur' },
                ],
                confirmPassword: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (value !== this.registerData.password) {
                                callback(new Error('两次输入的密码不一致'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                email: [
                    { required: true, message: '请输入邮箱', trigger: 'blur' },
                    { validator: this.validateEmail, trigger: 'blur' },
                ],
                verificationCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { validator: this.validateCode, trigger: 'blur' },
                ],
            }
        };
    },
    computed: {
        passwordFieldType() {
            return this.showPassword ? 'text' : 'password';
        },
        passwordFieldTypea() {
            return this.showPassworda ? 'text' : 'password';
        },
    },
    mounted() {
    },
    methods: {
        // 初始化极验验证
        initGeetest() {
            window.initGeetest4({
                captchaId: this.geetestConfig.captchaId,
                product: "bind",
                width: '100%'
            }, (captcha) => {
                this.geetestCaptcha = captcha;
                captcha.showCaptcha()
                captcha.onSuccess(() => {
                    const result = captcha.getValidate();
                    this.sendVerificationCode(result);
                    captcha.hideCaptcha();
                }).onError((error) => {
                    console.error('Geetest Error:', error);
                    this.$message.error('验证服务异常，请稍后重试');
                });
            });
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        togglePasswordVisibilitya() {
            this.showPassworda = !this.showPassworda;
        },

        validatePassword(rule, value, callback) {
            const pattern = /^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{6,48}$/;
            if (pattern.test(value)) {
                callback();
            } else {
                callback(new Error('密码不符合要求，长度在6到48个字符之间，并且至少包含字母、数字和符号中的两种。'));
            }
        },
        validateUsername(rule, value, callback) {
            const pattern = /^[\w-]{4,16}$/;
            if (pattern.test(value)) {
                callback();
            } else {
                callback(new Error('请输入4~16位用户名(不允许中文)'));
            }
        },
        validateEmail(rule, value, callback) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (pattern.test(value)) {
                callback();
            } else {
                callback(new Error('请输入邮箱(不支持中文)'));
            }
        },
        validateQQ(rule, value, callback) {
            const pattern = /^[1-9][0-9]{4,15}$/;
            if (pattern.test(value)) {
                callback();
            } else {
                callback(new Error('请输入正确的QQ号'));
            }
        },
        validateCode(rule, value, callback) {
            const pattern = /^\d{6}$/;
            if (pattern.test(value)) {
                callback();
            } else {
                callback(new Error('请输入正确的验证码'));
            }
        },

        async sendVerificationCode(geetestData) {
            if (!this.registerData.email) {
                this.$message.error('请先填写邮箱');
                return;
            }

            try {
                this.loadingyx = true;
                const response = await axios.post('https://cf-v2.uapis.cn/sendmailcode', {
                    type: 'register',
                    mail: this.registerData.email,
                    captcha_output: geetestData.captcha_output,
                    lot_number: geetestData.lot_number,
                    pass_token: geetestData.pass_token,
                    gen_time: geetestData.gen_time,
                }, {
                    headers: { 'Content-Type': 'application/json' }
                });

                if (response.data.state === 'success') {
                    this.$message.success("验证码发送成功");
                    this.startCountdown();
                } else {
                    this.$message.error(response.data.msg);
                }
            } catch (error) {
                console.error('发送验证码失败:', error);
                this.$message.error('验证码发送失败');
            } finally {
                this.loadingyx = false;
            }
        },
        async register() {
            this.$refs.registerForm.validate(async valid => {
                if (!valid) return;

                try {
                    this.loadingzc = true;
                    const response = await axios.get('https://cf-v2.uapis.cn/register', {
                        params: {
                            username: this.registerData.username,
                            password: this.registerData.password,
                            mail: this.registerData.email,
                            qq: this.registerData.qq,
                            code: this.registerData.verificationCode
                        }
                    });

                    if (response.data.state === 'success') {
                        this.$message.success("注册成功，即将跳转登录");
                        this.$router.push('/login');
                    } else {
                        this.$message.error(response.data.msg);
                    }
                } catch (error) {
                    console.error('注册失败:', error);
                    this.$message.error(error.response?.data?.msg || '注册请求失败');
                } finally {
                    this.loadingzc = false;
                }
            });
        },
        startCountdown() {
            this.cooldown = 60;
            const timer = setInterval(() => {
                if (this.cooldown <= 0) {
                    clearInterval(timer);
                    return;
                }
                this.cooldown--;
            }, 1000);
        }
    }
}
</script>

<style lang="less">
.center-content {
    display: flex;
    flex-direction: column;
    /* 垂直居中时，垂直排列子元素 */
    justify-content: center;
    /* 垂直居中 */
    align-items: center;
    /* 水平居中 */
    height: 90%;
    /* 使其占据整个父元素的高度 */
}

.custom-form {
    width: 100%;
    max-width: 360px;
    /* 设置最大宽度为360px */
}
</style>